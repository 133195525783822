import { TeaserSmall as TeaserSmallComponent, TeaserType } from 'components/TeaserSmall';

import type { TeaserSmall as TTeaserSmall } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';

export interface ITeaserSmall extends ISharedTemplateProps {
  templateProps: TTeaserSmall & {
    teasersSmall: TeaserType[]; // is extended dynamically... see also e.g.: getProductsPageData.ts line 74
  };
}

export const TeaserSmall = ({ templateProps: { teasersSmall } }: ITeaserSmall) => {
  return <TeaserSmallComponent teasersSmall={teasersSmall} />;
};
