import styled from 'styled-components';

export const Teaser = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fdf9f4;
  border-radius: 16px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
  @media (min-width: 768px) {
    width: 60%;
    padding: 0 64px;
    text-align: left;
  }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    width: 40%;
  }
`;
