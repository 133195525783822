import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { Grid, GridRow } from 'components/Grid';
import { LiquidButton } from 'components/LiquidButton';
import { NextLink } from 'components/NextLink';
import { Text as Paragraph, Title } from 'components/Typography';
import { useEffect, useState } from 'react';
import { LinksService } from 'service/links/linksService';
import { COLORS } from 'styles/constants';
import { ButtonContainer, Content, Teaser } from './styles';
import { Slider } from '../SliderVanilla';

export type TeaserType = {
  id?: string;
  link?: {
    url: string;
    displayName: string;
  };
  heading?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
};

export interface ITeaserSmall {
  teasersSmall: TeaserType[]; // is extended dynamically... see also e.g.: getProductsPageData.ts line 74
}

export const TeaserSmall = ({ teasersSmall }: ITeaserSmall) => {
  const { pathTranslations } = useGlobalContext();
  const teasers = teasersSmall;
  const [filteredTeasers, setFilteredTeasers] = useState<TeaserType[]>();

  useEffect(() => {
    const currentDate = new Date();
    setFilteredTeasers(
      teasers.filter(
        (item) =>
          new Date(item?.startDate || '') < currentDate &&
          new Date(item?.endDate || '') > currentDate,
      ),
    );
  }, []);

  return (
    <Grid>
      <GridRow className="mb-10">
        {filteredTeasers?.length ? (
          <Slider isFullWidth>
            {filteredTeasers?.map((teaser: TeaserType, index: number) => (
              <Teaser key={`teaser-${teaser.id ?? index}`}>
                <Content>
                  <Title type="xs" tag="h4" className="mt-6 md:mt-0">
                    {teaser.heading}
                  </Title>
                  <Paragraph type="md" tag="div" className="mt-2">
                    {teaser.description}
                  </Paragraph>
                </Content>
                <ButtonContainer>
                  <NextLink
                    href={
                      LinksService.getInternalLinks({ item: teaser?.link, pathTranslations }) ||
                      teaser?.link?.url ||
                      ''
                    }
                  >
                    <LiquidButton
                      text={teaser?.link?.displayName || 'Teaser'}
                      height={50}
                      width={250}
                      color={COLORS.primary}
                      textColor={COLORS.white}
                    />
                  </NextLink>
                </ButtonContainer>
              </Teaser>
            ))}
          </Slider>
        ) : null}
      </GridRow>
    </Grid>
  );
};
